import * as React from 'react';
import { Button, Card, HRule, StorageService, fitToContainer } from '@imposium-hub/components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import VideoPreviewer from './VideoPreviewer';
import { faClipboard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EXPERIENCES_COPY } from '../../constants/copy';

interface IExperienceVariablesProps {
    inventory: any; // Experience 'input' object
    containerWidth: number;
    playbackSettings?: any;
}

interface IExperienceVariableState {
    show: boolean;
}

class ExperienceVariables extends React.Component<
    IExperienceVariablesProps,
    IExperienceVariableState
> {
    private static readonly VARS_CACHE_KEY: string = 'exp_manager_vars_open';

    private static readonly MEDIA_MAX_HEIGHT: number = 280;

    private static readonly NAME_OFFSET: number = 100;

    constructor(p: IExperienceVariablesProps) {
        super(p);

        this.state = {
            show: false
        };

        StorageService.getItem(ExperienceVariables.VARS_CACHE_KEY)
            .then((toggle: boolean) => this.setState({ show: toggle }))
            .catch((e) => {
                console.error(e);
            });
    }

    private onToggle = (toggle: boolean): void => {
        this.setState({ show: toggle });
        void StorageService.setItem(ExperienceVariables.VARS_CACHE_KEY, toggle);
    };

    private onCopy = () => {
        const { inventory } = this.props;
        const inventoryArray: any = Object.entries(inventory).sort();
        const JASON = {};

        for (const idx of inventoryArray) {
            const inv = idx[1];
            const name = idx[0];
            JASON[name] = inv.value ? inv.value : null;
        }

        navigator.clipboard.writeText(JSON.stringify(JASON)).catch((e) => console.error(e));
    };

    public render = (): JSX.Element => {
        const { show } = this.state;
        const { inventory } = this.props;
        const inventoryArray: any = Object.entries(inventory).sort();

        if (!inventory || inventoryArray.length === 0) {
            return null;
        }

        const cards: JSX.Element[] = inventoryArray
            .map((inventoryEntry: any, i: number) => {
                const [id, inventoryItem] = inventoryEntry;
                let variablePreview: JSX.Element;

                if (inventoryItem['name'] === undefined || inventoryItem['name'] === '') {
                    return null;
                }

                if (
                    inventoryItem['type'] === 'number' ||
                    inventoryItem['type'] === 'text' ||
                    inventoryItem['type'] === 'enum' ||
                    inventoryItem['type'] === 'boolean'
                ) {
                    variablePreview = (
                        <div className='variable-preview-frame text'>
                            {`${inventoryItem['value']}`}
                        </div>
                    );
                }

                if (inventoryItem['type'] === 'image' && inventoryItem.hasOwnProperty('url')) {
                    const dims = fitToContainer(
                        {
                            width: this.props.containerWidth - ExperienceVariables.NAME_OFFSET,
                            height: ExperienceVariables.MEDIA_MAX_HEIGHT
                        },
                        {
                            width: inventoryItem.width,
                            height: inventoryItem.height
                        },
                        {
                            scaleMode: 'proportionalInside',
                            hAlign: 'left',
                            vAlign: 'center'
                        }
                    );

                    const style = {
                        width: dims.width,
                        height: dims.height
                    };

                    variablePreview = (
                        <img
                            className='variable-preview-frame'
                            style={style}
                            src={inventoryItem['url']}
                        />
                    );
                }

                if (inventoryItem['type'] === 'audio' && inventoryItem.hasOwnProperty('url')) {
                    variablePreview = (
                        <audio
                            controls
                            src={inventoryItem['url']}
                        />
                    );
                }

                if (inventoryItem['type'] === 'video' && inventoryItem.hasOwnProperty('url')) {
                    variablePreview = (
                        <VideoPreviewer
                            invId={id}
                            maxWidth={
                                this.props.containerWidth - ExperienceVariables.NAME_OFFSET - 90
                            }
                            maxHeight={ExperienceVariables.MEDIA_MAX_HEIGHT - 90}
                            inventory={inventoryItem}
                        />
                    );
                }

                if (!variablePreview) {
                    variablePreview = <div className='variable-preview-frame text'>Not found</div>;
                }

                return (
                    <div key={id}>
                        <div className='variable-preview-wrapper'>
                            <div className='variable-preview-name'>{inventoryItem['name']}</div>
                            {variablePreview}
                        </div>
                        {i < inventoryArray.length - 1 && <HRule style='subtle' />}
                    </div>
                );
            })
            .filter((o) => o !== null);

        if (cards.length === 0) {
            return null;
        }

        const copyButton = (
            <Button
                key='showCopy'
                tooltip={EXPERIENCES_COPY.copyVars}
                style='subtle'
                onClick={() => this.onCopy()}>
                <FontAwesomeIcon icon={faClipboard} />
            </Button>
        );

        return (
            <div className='experience-variables'>
                <HRule style='subtle' />
                <Card
                    collapsable
                    open={show}
                    style='small'
                    title='Variables'
                    onToggle={this.onToggle}
                    buttons={[copyButton]}>
                    {cards}
                </Card>
            </div>
        );
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({}, dispatch);
};

const mapStateToProps = (state): any => {
    return { playbackSettings: state.playbackSettings };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExperienceVariables);
