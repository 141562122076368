import * as React from 'react';
import hotkeys from 'hotkeys-js';
import ExperiencesFilterControls from './ExperiencesFilterControls';
import ExperiencesTableDateCell from './ExperiencesTableDateCell';
import ExperiencesTableDurationCell from './ExperiencesTableDurationCell';
import ExperiencesTableAspectCell from './ExperiencesTableAspectCell';
import ExperiencesTableModerationCell from './ExperiencesTableModerationCell';
import ExperiencesTableVariableCell from './ExperiencesTableVariableCell';
import { DataTable } from '@imposium-hub/components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { EXPERIENCES_COPY } from '../../constants/copy';
import ExperiencesTableBatchIdCell from './ExperiencesTableBatchIdCell';

import {
    RowSelectHotkey,
    PageSelectHotkey,
    getCurrentIndex,
    calculateNextRow,
    calculateNextPage
} from '../../util/paging';

import {
    filterExperiencesBy,
    selectExperience,
    getExperiences
} from '../../redux/actions/experiences';
import { parseInventory } from '../../util/story-parsing-utils';

interface IExperiencesTableProps {
    story: any;
    tags: any[];
    experiencesList: any;
    activeBatch: any;
    getExperiences: () => any;
    selectExperience: (selectedExperience: any) => any;
    filterExperiencesBy: (filter: any) => any;
    columnMasks: any[];
}

interface IExperiencesTableState {
    filtersHeight: number;
    dynamicColumns: any[];
}

class ExperiencesTable extends React.Component<IExperiencesTableProps, IExperiencesTableState> {
    private static readonly DEFAULT_FILTERS_HEIGHT: number = 81;

    private static readonly DEFAULT_COLUMNS: any[] = [
        {
            accessor: 'date_created',
            Header: EXPERIENCES_COPY.table.createdHeader,
            Cell: (cell: any) => <ExperiencesTableDateCell cell={cell} />,
            width: 110
        },
        {
            accessor: 'moderation_status',
            Header: EXPERIENCES_COPY.table.moderationHeader,
            Cell: (cell: any) => <ExperiencesTableModerationCell cell={cell} />,
            minWidth: 170
        },
        {
            accessor: 'duration',
            disableSortBy: true,
            Header: EXPERIENCES_COPY.table.durationHeader,
            Cell: (cell: any) => <ExperiencesTableDurationCell cell={cell} />,
            minWidth: 75,
            maxWidth: 75
        },
        {
            accessor: 'aspect_ratio',
            disableSortBy: true,
            Header: EXPERIENCES_COPY.table.aspectHeader,
            Cell: (cell: any) => <ExperiencesTableAspectCell cell={cell} />,
            minWidth: 100,
            maxWidth: 100
        },
        {
            accessor: 'batch_id',
            disableSortBy: true,
            Header: EXPERIENCES_COPY.table.batchIdHeader,
            Cell: (cell: any) => <ExperiencesTableBatchIdCell cell={cell} />,
            minWidth: 210,
            maxWidth: 210
        }
    ];

    private readonly HOTKEYS: [string, () => any][] = [
        ['up', () => this.doSelectExperienceOnKeyPress('up')],
        ['down', () => this.doSelectExperienceOnKeyPress('down')],
        ['left', () => this.doPageOnKeyPress('left')],
        ['right', () => this.doPageOnKeyPress('right')],
        ['shift+r', () => this.props.getExperiences()]
    ];

    constructor(p: IExperiencesTableProps) {
        super(p);

        this.state = {
            filtersHeight: ExperiencesTable.DEFAULT_FILTERS_HEIGHT,
            dynamicColumns: ExperiencesTable.DEFAULT_COLUMNS
        };
    }

    public componentDidMount = (): void => {
        this.HOTKEYS.forEach((hotkey) => {
            const [key, lambda] = hotkey;
            hotkeys(key, lambda);
        });

        if (
            Object.keys(this.props.story.data).length > 0 &&
            Object.keys(this.props.experiencesList.data).length === 0
        ) {
            this.props.getExperiences();
        }

        if (
            Object.keys(this.props.activeBatch.data).length > 0 &&
            !this.props.experiencesList.loading
        ) {
            this.props.getExperiences();
        }
    };

    public componentWillUnmount() {
        this.HOTKEYS.forEach((hotkey) => {
            const [key, lambda] = hotkey;
            hotkeys.unbind(key, lambda);
        });
    }

    public componentDidUpdate = (prevProps: IExperiencesTableProps): void => {
        const {
            columnMasks,
            story: {
                data: { id }
            },
            experiencesList: {
                filters,
                data: { experiences }
            }
        } = this.props;

        if (id !== prevProps.story.data.id || filters !== prevProps.experiencesList.filters) {
            this.props.getExperiences();
        }

        if (
            experiences !== prevProps.experiencesList.data.experiences ||
            (columnMasks !== prevProps.columnMasks && experiences !== undefined)
        ) {
            this.prepDynamicColumns();
        }
    };

    private doSelectExperienceOnKeyPress = (direction: RowSelectHotkey): void => {
        const {
            experiencesList: {
                data: { experiences },
                selected: { id }
            }
        } = this.props;
        const currentSelectedIndex: number = getCurrentIndex(experiences, 'id', id);
        const nextRowIndex: number = calculateNextRow(experiences, currentSelectedIndex, direction);

        if (nextRowIndex >= 0) {
            this.props.selectExperience(experiences[nextRowIndex]);
        }
    };

    private doPageOnKeyPress = (direction: PageSelectHotkey): void => {
        const {
            experiencesList: {
                data: { total_pages: totalPages, page }
            }
        } = this.props;
        const nextPage = calculateNextPage(totalPages, page || 1, direction);

        if (nextPage >= 0) {
            this.props.filterExperiencesBy({ page: nextPage });
        }
    };

    private prepDynamicColumns = (): void => {
        const {
            columnMasks,
            experiencesList: {
                data: { experiences }
            }
        } = this.props;
        const hiddenColumns = columnMasks.reduce((p, c) => (!c.show ? [...p, c.name] : p), []);

        const inventoryColumns: any[] = [];
        const storyInventory = parseInventory(this.props.story.data);

        for (const key in storyInventory) {
            if (storyInventory.hasOwnProperty(key)) {
                const inventoryItem = storyInventory[key];
                const Header = inventoryItem.name === '' ? key : inventoryItem.name;
                if (hiddenColumns.indexOf(key) === -1) {
                    inventoryColumns.push({
                        Header,
                        accessor: `${key}`,
                        id: `inventory-${key}`,
                        disableSortBy: true,
                        Cell: (tableState: any) => (
                            <ExperiencesTableVariableCell
                                inventoryKey={key}
                                tableState={tableState}
                            />
                        )
                    });
                }
            }
        }

        for (const experience of experiences) {
            for (const inventory in experience.inventory) {
                if (inventory) {
                    const mask = experience.inventory[inventory];
                    const Header = mask.name === '' ? inventory : mask.name;
                    if (
                        hiddenColumns.indexOf(inventory) === -1 &&
                        inventoryColumns.findIndex((e) => e.accessor === inventory) === -1
                    ) {
                        inventoryColumns.push({
                            Header,
                            accessor: `${inventory}`,
                            id: `inventory-${inventory}`,
                            disableSortBy: true,
                            Cell: (tableState: any) => (
                                <ExperiencesTableVariableCell
                                    inventoryKey={inventory}
                                    tableState={tableState}
                                />
                            )
                        });
                    }
                }
            }
        }

        inventoryColumns.sort((a, b) => a.accessor.localeCompare(b.accessor));

        const dynamicColumns = [...ExperiencesTable.DEFAULT_COLUMNS, ...inventoryColumns];

        this.setState({ dynamicColumns });
    };

    private selectExperience = (row: any): void => {
        if (row && row.original.id !== this.props.experiencesList.selected.id) {
            this.props.selectExperience(row.original);
        }
    };

    private onSort = (sortState: any[]): void => {
        if (typeof sortState[0] === 'object') {
            this.props.filterExperiencesBy({
                order: sortState[0].id,
                order_direction: sortState[0].desc ? 'desc' : 'asc'
            });
        }
    };

    private onPage = (pageIndex: number, pageSize: number): void => {
        const {
            experiencesList: {
                filters: { page, items_per_page }
            }
        } = this.props;
        const updatedFilters: any = {};

        if (pageIndex !== page) {
            updatedFilters.page = pageIndex;
        }
        if (pageSize !== items_per_page) {
            updatedFilters.items_per_page = items_per_page;
        }

        if (Object.keys(updatedFilters).length) {
            this.props.filterExperiencesBy(updatedFilters);
        }
    };

    private itemsPerPageHandler(items) {
        this.props.filterExperiencesBy({ items_per_page: items });
    }

    private getFilteredExperiences() {
        const {
            experiencesList: {
                filters,
                data: { experiences }
            }
        } = this.props;

        if (experiences) {
            let filtered = [...experiences];
            filtered = filtered.filter(
                (e) =>
                    filters.status.indexOf(e.moderation_status) !== -1 ||
                    filters.status.length === 0
            );

            return filtered;
        }

        return [];
    }

    public render = (): JSX.Element => {
        const { dynamicColumns, filtersHeight } = this.state;

        const {
            experiencesList: {
                loading,
                selected: { id },
                filters: {
                    page,
                    order,
                    order_direction: orderDirection,
                    items_per_page: itemsPerPage
                },
                data: { experience_count: experienceCount, total_pages }
            }
        } = this.props;

        const sortBy =
            order && orderDirection ? [{ id: order, desc: orderDirection === 'desc' }] : undefined;

        const highlightBy = typeof id === 'string' ? { key: 'id', value: id } : undefined;

        const totalPages = total_pages === 0 ? 1 : total_pages;

        return (
            <div className='experiences-table'>
                <ExperiencesFilterControls
                    onHeightChange={(h) => this.setState({ filtersHeight: h })}
                />

                <div
                    style={{
                        height: `calc(100% - ${filtersHeight}px)`,
                        width: '100%'
                    }}>
                    <DataTable
                        disableKeyScroll
                        data={this.getFilteredExperiences()}
                        columns={dynamicColumns}
                        showInterstitial={loading}
                        currentPage={page}
                        totalPages={totalPages}
                        itemsPerPage={itemsPerPage}
                        onItemsPerPage={(i) => this.itemsPerPageHandler(i)}
                        totalItems={experienceCount}
                        onPage={this.onPage}
                        sortBy={sortBy}
                        onSort={this.onSort}
                        highlightBy={highlightBy}
                        onRowClick={this.selectExperience}
                    />
                </div>
            </div>
        );
    };
}

const mapStateToProps = (state): any => {
    return {
        tags: state.tags,
        story: state.story,
        experiencesList: state.experiencesList,
        activeBatch: state.activeBatch,
        columnMasks: state.columnMasks
    };
};

const mapDispatchToProps = (dispatch): any => {
    return bindActionCreators(
        {
            filterExperiencesBy,
            selectExperience,
            getExperiences
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ExperiencesTable);
