import {
    filterExperiencesBy,
    filterExperiencesByInventory,
    removeExperiencesInventoryFilter
} from './experiences';
import { browserHistory } from 'react-router';

const actions: any = {
    ADD_TAG: 'tags/ADD_TAG',
    ADD_TAGS: 'tags/ADD_TAGS',
    REMOVE_TAG: 'tags/REMOVE_TAG',
    UPDATE_TAGS: 'tags/UPDATE_TAGS',
    RESET_TAGS: 'tags/RESET_TAGS',
    SELECT_TAG: 'tags/SELECT_TAG'
};

export const seedTags = (tags: any[]): any => {
    return (dispatch) => {
        tags.forEach((tag: any) => {
            dispatch(addTag(tag, false));
        });
    };
};

export const resetTags = (): any => {
    return { type: actions.RESET_TAGS };
};

export const addTags = (newTags: any, userInvoked: boolean = true): any => {
    return (dispatch, getStore) => {
        const {
            tags,
            experiencesList: { filters }
        } = getStore();
        const values = [];
        let updateTags = [...newTags];
        if (tags) {
            const mergeTags = [...tags, ...newTags];
            const ids = mergeTags.map(({ id }) => id);
            updateTags = mergeTags.filter(({ id }, index) => !ids.includes(id, index + 1));
        }
        for (const idx in newTags) {
            if (idx) {
                const [type, value] = newTags[idx].id.split(':');
                if (type === 'batch') {
                    values.push(value);
                }
            }
        }

        const nextPage: number = userInvoked ? 1 : filters.page;
        dispatch({ type: actions.ADD_TAGS, updateTags });
        dispatch(filterExperiencesBy({ batch_id: values.join(','), page: nextPage }));
    };
};

export const addTag = (tag: any, userInvoked: boolean = true): any => {
    return (dispatch, getStore) => {
        const {
            tags,
            experiencesList: { filters }
        } = getStore();
        const [type, value] = tag.id.split(':');
        const nextPage: number = userInvoked ? 1 : filters.page;

        let freshExperienceModerationFilters: string[];
        // let currBatchTag: any;
        let currInventoryTag: any;
        let inventoryValueSplit: any[];

        if (type === 'id') {
            dispatch(filterExperiencesBy({ experience_id: value, page: nextPage }));
        }

        if (type === 'batch_id') {
            dispatch(filterExperiencesBy({ batch_id: value, page: nextPage }));
        }

        if (type === 'moderation') {
            freshExperienceModerationFilters = [...filters.status, value];
            dispatch(
                filterExperiencesBy({ status: freshExperienceModerationFilters, page: nextPage })
            );
        }

        if (type === 'batch') {
            let updateTags = [...tags];
            if (tags.length > 0) {
                for (const idx of updateTags) {
                    if (idx.id.includes('batch')) {
                        const index = updateTags.findIndex((i) => i === idx);
                        updateTags = updateTags.filter((_, i) => i !== index);
                    }
                }
            }

            dispatch({ type: actions.UPDATE_TAGS, updateTags });

            dispatch(filterExperiencesBy({ batch_id: value, page: nextPage }));
        }

        if (type === 'inventory_item') {
            inventoryValueSplit = value.split(' | ');

            currInventoryTag = tags.find((t: any) => t.id.includes('inventory_item'));

            if (currInventoryTag) {
                dispatch(removeTag(tags.indexOf(currInventoryTag), false));
            }

            dispatch(filterExperiencesByInventory(inventoryValueSplit[0], inventoryValueSplit[1]));
        }

        dispatch({ type: actions.ADD_TAG, tag });
    };
};

export const removeTag = (
    tagIndex: number,
    userInvoked: boolean = true,
    filterDataSet: boolean = false
): any => {
    return (dispatch, getStore) => {
        const {
            tags,
            experiencesList: { filters },
            batchesList: {
                data: { batches }
            },
            story: {
                data: { id: storyId }
            }
        } = getStore();

        let freshModerationFilters: string[];
        if (tags.length > 0) {
            const tag: any = tags[tagIndex];
            const [type, value] = tag.id.split(':');
            const inventoryValueSplit = value.split(' | ');

            if (type === 'id') {
                dispatch(filterExperiencesBy({ experience_id: '', page: 1 }));
            }

            if (type === 'moderation') {
                freshModerationFilters = filters.status.filter((s) => s !== value);
                dispatch(filterExperiencesBy({ status: freshModerationFilters, page: 1 }));
            }

            if (type === 'batch' && userInvoked) {
                const orgId = new URLSearchParams(window.location.search).get('organization_id');
                const ids = new URLSearchParams(window.location.search).get('batch_id').split(',');
                if (ids.length === 1) {
                    browserHistory.replace(`/${storyId}?organization_id=${orgId}`);
                    dispatch(filterExperiencesBy({ batch_id: '', page: 1 }));
                    dispatch({ type: 'batches/SELECT', selectedBatch: {} });
                } else {
                    const batchIds = ids.filter((id) => id !== value);
                    const batch_id = batchIds.join(',');
                    browserHistory.replace(
                        `/${storyId}?organization_id=${orgId}&batch_id=${batch_id}`
                    );
                    dispatch(filterExperiencesBy({ batch_id, page: 1 }));
                    const multiSelected = [];
                    for (const idx of batchIds) {
                        if (idx) {
                            multiSelected.push(batches.filter((b) => b.id === idx)[0]);
                        }
                    }
                    if (multiSelected.length > 1) {
                        dispatch({ type: 'batches/MULTI_SELECT', selectedBatches: multiSelected });
                    } else {
                        dispatch({ type: 'batches/SELECT', selectedBatch: multiSelected[0] });
                    }
                }
            }

            if (type === 'inventory_item') {
                dispatch(removeExperiencesInventoryFilter(inventoryValueSplit[0]));
            }
        }

        dispatch({ type: actions.REMOVE_TAG, tagIndex });
    };
};

export const selectTag = (tag: any, userInvoked: boolean = true): any => {
    return (dispatch, getStore) => {
        const {
            experiencesList: { filters }
        } = getStore();
        const [type, value] = tag.id.split(':');
        const nextPage: number = userInvoked ? 1 : filters.page;

        if (type === 'moderation') {
            const filterObj = { status: [value], rendered_only: 0, page: nextPage };
            dispatch(filterExperiencesBy(filterObj));
        }

        dispatch({ type: actions.SELECT_TAG, tag });
    };
};

export default actions;
