import * as React from 'react';

interface IExperiencesTableBatchIdCell {
    cell: any; // React table 'cell' wrapper
}

const ExperiencesTableBatchIdCell: React.FC<IExperiencesTableBatchIdCell> = (
    p: IExperiencesTableBatchIdCell
) => {
    const {
        row: {
            original: { batch_id }
        }
    } = p.cell;

    return <div>{batch_id}</div>;
};

export default ExperiencesTableBatchIdCell;
